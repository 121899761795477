import React, {useEffect,useState} from 'react';

import styles from './styles.css'
import m from '../../assets/images/m.png'
import useWindowDimensions from '../../helpers/useWindowDimensions';
import { FaBars, FaWindowClose } from "react-icons/fa";
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export const NavBar = function NavBar(props) {
  const { height, width } = useWindowDimensions();
  const [dropDown, setDropDown] = useState(false);
  const history = useHistory();
  const location = useLocation();
  ReactGA.initialize(process.env.REACT_APP_GA);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  },[location])

  function chooseRoute(tab) {
    if (tab==1) {
      history.push({pathname: "/aboutme"})
      setDropDown(false)
    }
    if (tab==2) {
      history.push({pathname: "/contactme"})
      setDropDown(false)
    }
  }
  return (
  <div style={{width:'100%', alignItems:'center', display:'flex', alignItems:'center', flexDirection:'column'}}>
    <div style={{width:'100%', alignItems:'center', display:'flex', alignItems:'center', justifyContent:'center'}}>

    <div style={{width:'100%',maxWidth:1400,display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
      <div style={{width:100, margin:10, cursor:'pointer', position:'relative'}} onClick={()=> history.push({pathname: "/"}) }>
        <img src={m} className="M-Logo2"/>
        <div style={{position:'absolute', top:0, left:(width>800 ? 8:5), right:0, bottom:0}}>
            <img src={m} className="M-Logo"/>
        </div>
      </div>

      {width>900 ? (
      <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        <div className="title" onClick={()=> history.push({pathname: "/aboutme"}) }>
          About Me
        </div>
        <div className="ContactMe" onClick={()=> history.push({pathname: "/contactme"}) }>
          Say Hello
        </div>
      </div>)
      :(
        <h2 style={{margin:10, cursor:'pointer'}}>
          {!dropDown ? (<FaBars onClick={()=> setDropDown(true)}/>):(<FaWindowClose onClick={()=> setDropDown(false)}/>)}
        </h2>
      )}
    </div>



    </div>

    {width<900 && dropDown ? (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <div className="title" style={{marginBottom:15}} onClick={()=> chooseRoute(1) }>
        About Me
      </div>
      <div className="ContactMe" style={{marginBottom:15}} onClick={()=> chooseRoute(2) }>
        Say Hello
      </div>
    </div>):(null)}

  </div>
  );
};
