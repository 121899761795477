export function gameResult(boardArray) {

  var array1 = boardArray[0].toString() + boardArray[1].toString() + boardArray[2].toString()
  var array2 = boardArray[3].toString() + boardArray[4].toString() + boardArray[5].toString()
  var array3 = boardArray[6].toString() + boardArray[7].toString() + boardArray[8].toString()

  var array4 = boardArray[0].toString() + boardArray[3].toString() + boardArray[6].toString()
  var array5 = boardArray[1].toString() + boardArray[4].toString() + boardArray[7].toString()
  var array6 = boardArray[2].toString() + boardArray[5].toString() + boardArray[8].toString()

  var array7 = boardArray[0].toString() + boardArray[4].toString() + boardArray[8].toString()
  var array8 = boardArray[6].toString() + boardArray[4].toString() + boardArray[2].toString()

  if (array1 === "111" || array2 === "111" || array3 === "111" || array4 === "111" || array5 === "111" || array6 === "111" || array7 === "111" || array8 === "111" ) {
    return 1;
  }
  else if (array1 === "222" || array2 === "222" || array3 === "222" || array4 === "222" || array5 === "222" || array6 === "222" || array7 === "222" || array8 === "222" ) {
    return -1;
  }
  else {
    return 0;
  }

}
