import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { Home } from './Home/Home';
import { NavBar } from './NavBar/NavBar';
import { AboutMe } from './AboutMe/AboutMe';
import { ContactMe } from './ContactMe/ContactMe';
import { Admin } from './Admin/Admin';

const Router = function Router(props) {
  return (
    <div>
      <BrowserRouter>
        <div>
          <NavBar />
          <Route path='/' exact component={Home} />
          <Route path='/AboutMe' exact component={AboutMe} />
          <Route path='/contactme' exact component={ContactMe} />
          <Route path='/home/:id' exact component={Home} />
          <Route path='/home' exact component={Home} />
          <Route path='/admin' exact component={Admin} />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default Router;
