import {optionsList} from './optionsList.js';
import {gameResult} from './determineWinner.js';

export function minimax([boardArray, isMaximizing]) {


  let result = gameResult(boardArray);
  let options  = optionsList(boardArray);
  let minimaxArray;

  if (result !== 0) {
    return result
  }
  else if (options === undefined || options.length === 0) {
    return 0;
  }
  else{
    if (isMaximizing) {
      minimaxArray = options.map(function(option, index){
          let newBoard = boardArray.slice();
          newBoard[option] = 1;

          let maxArray = minimax([newBoard,false])

          if (Array.isArray(maxArray)) {
            return Math.min(...maxArray)
          }
          else {
            return maxArray;
          }
      })
    }
    else {
      minimaxArray = options.map(function(option, index){

          let newBoard = boardArray.slice();
          newBoard[option] = 2;

          let minArray = minimax([newBoard,true])

          if (Array.isArray(minArray)) {
            return Math.max(...minArray)
          }
          else {
            return minArray;
          }

      })

    }

  }
  

  return minimaxArray
}
