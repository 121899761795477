
export const Result = (props) => {
    const { result, reset } = props;

    if (result === 0) return null;
    return (
        <div className="Result-Background">
            <button className="Result-Button" onClick={()=> reset()}>Reset</button>
        </div>
    );
}


export default Result