import React, {useEffect,useState} from 'react';
import Typist from 'react-typist';
import Wave from 'react-wavify'
import { useHistory, useLocation } from 'react-router-dom';

import { FiMail } from "react-icons/fi";
import { FaPhone } from "react-icons/fa";

import m from '../../assets/images/m.png'
import useWindowDimensions from '../../helpers/useWindowDimensions';
import styles from './styles.css';

export const Footer = function Footer(props) {
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();

  function showLocation() {
    console.log(location.pathname)
    if (location.pathname == '/') {
      window.scrollTo(0, 0)
    }
    else {
      history.push({pathname: "/"})
    }
  }

  function openEmail() {
    window.location.href = `mailto:mfmurray@umich.edu`;
  }
  function openPhone() {
    window.location.href = `tel:3139106492`;
  }

  if (!props.box) {
    return (
      <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column', backgroundColor:'#16bac5', position:'relative'}}>

        <div className="Logo-Footer-View" onClick={()=> showLocation() }>
          <img src={m} className="M-Logo2-Footer"/>
          <div style={{position:'absolute', top:10, left:5, right:0, bottom:0}}>
              <img src={m} className="M-Logo-Footer"/>
          </div>
        </div>

        <div className="Footer-Quote">Learning code and trying to limit caffeine content everyday</div>

        <div className="Footer-Contact">
          <div style={{display:'flex', flexDirection:'row', marginTop:15, cursor:'pointer'}} onClick={()=> openEmail()}>
            <FiMail color="white" size={18}/>
            <div style={{color:'white', fontSize:18, marginLeft:5}}>mfmurray@umich.edu</div>
          </div>
          <div style={{display:'flex', flexDirection:'row', marginTop:15, cursor:'pointer'}} onClick={()=> openPhone()}>
            <FaPhone color="white" size={18}/>
            <div style={{color:'white', fontSize:18, marginLeft:5}}>(313) 910-6492</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column', backgroundColor:'#16bac5', position:'relative'}}>
      <div style={{width:'100%', backgroundColor:'white', height:60, position:'absolute', top:0}}></div>

      {width>700 ? (
      <div className="Chat-Footer-Grid">
        <div className="Chat-Footer-Title">Wanna Chat</div>
        <div className="Chat-Footer-Description">Interested in reaching out? Feel free to send me an email or call me by phone.</div>
        <div className="Chat-Footer-Button">
          <div className="Chat-Footer-Button-Center" onClick={()=> history.push({pathname: "/contactme"})}>Contact Me</div>
        </div>
      </div> ):(
        <div className="Chat-Footer-Grid2">
          <div className="Chat-Footer-Title2">Wanna Chat</div>
          <div className="Chat-Footer-Description2">Interested in reaching out? Feel free to send me an email or call me by phone.</div>
          <div className="Chat-Footer-Button2">
            <div className="Chat-Footer-Button-Center" onClick={()=> history.push({pathname: "/contactme"})}>Contact Me</div>
          </div>
        </div>
      )}

      <div className="Logo-Footer-View" onClick={()=> showLocation() }>
        <img src={m} className="M-Logo2-Footer"/>
        <div style={{position:'absolute', top:10, left:5, right:0, bottom:0}}>
            <img src={m} className="M-Logo-Footer"/>
        </div>
      </div>

      <div className="Footer-Quote">Learning code and trying to limit caffeine content everyday</div>

      <div className="Footer-Contact">
        <div style={{display:'flex', flexDirection:'row', marginTop:15, cursor:'pointer', alignItems:'center', justifyContent:'center'}} onClick={()=> openEmail()}>
          <FiMail color="white" size={18}/>
          <div style={{color:'white', fontSize:18, marginLeft:5}}>mfmurray@umich.edu</div>
        </div>
        <div style={{display:'flex', flexDirection:'row', marginTop:15, cursor:'pointer', alignItems:'center', justifyContent:'center'}} onClick={()=> openPhone()}>
          <FaPhone color="white" size={18}/>
          <div style={{color:'white', fontSize:18, marginLeft:5}}>(313) 910-6492</div>
        </div>
      </div>
    </div>
  );
};
