import React from 'react';

import Router from './containers/Router';
import './App.css';


function App() {

  return <Router style={{minHeight: '100vh'}}/>;
}

export default App;
