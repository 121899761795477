export function optionsList(boardArray) {
  let options = [];
  let i;
  for (i = 0; i < boardArray.length; i++) {
    if (boardArray[i] === 0) {
      options.push(i)
    }
  }
  
  return options;
}
