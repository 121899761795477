import React, {useEffect,useState} from 'react';

import useWindowDimensions from '../../helpers/useWindowDimensions';
import styles from './styles.css';
import { getMessages, getTracker } from '../../helpers/api';
import { Footer } from '../../components/Footer/Footer';
import { AdminLogin } from '../../components/Admin/AdminLogin';
import { AdminMessages } from '../../components/Admin/AdminMessages';
import { AdminTracker } from '../../components/Admin/AdminTracker';

export const Admin = function Admin(props) {
  const { height, width } = useWindowDimensions();

  const [admin, setAdmin] = useState(false);

  const rememberMe = localStorage.getItem('rememberMe')

  const [messages, setMessages] = useState([]);
  const [trackers, setTrackers] = useState([]);

  useEffect(() => {
    if (rememberMe) {
      loadMessages()
    }
  }, []);

  function login() {
    localStorage.setItem('rememberMe', true);
    setAdmin(true)
    loadMessages()
  }

  function loadMessages() {
    getMessages().then((data)=> {
      console.log(data)
      setMessages(data)
      loadTracker()
    }, (err) => {
      console.log(err);
    });
  }

  function loadTracker() {
    getTracker().then((data)=> {
      console.log(data)
      setTrackers(data)
    }, (err) => {
      console.log(err);
    });
  }

  console.log('rememberMe ',rememberMe)

  return (
    <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column'}}>
      {!admin && !rememberMe ? (
        <AdminLogin login={()=> login()}/>
      ):(
        <div>
          <AdminMessages messages={messages} loadMessages={()=> loadMessages()}/>
          <div style={{height:80}}></div>
          <AdminTracker trackers={trackers} loadMessages={()=> loadMessages()}/>
        </div>
      )}
    </div>
  );
};
