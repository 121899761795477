import { useEffect, useState, useRef } from 'react'

export const ClickBlock = (props) => {
    const { click, item } = props;
    const hoverRef = useRef(null)
    const isHover = useHover(hoverRef);

    if (item === 0 && isHover) {
        return <div ref={hoverRef} className="hover-point" style={{cursor:'pointer'}} onClick={() => click()}>X</div>
    }
    if (item === 0) return <div ref={hoverRef} className="hover-point" onClick={() => click()}></div>
    return (
        <div ref={hoverRef} className="hover-point" onClick={() => click()}></div>
    )
}

export default ClickBlock;







function useHover(elementRef) {
    const [value, setValue] = useState(false)
  
    const handleMouseEnter = () => setValue(true)
    const handleMouseLeave = () => setValue(false)
  
    useEffect(() => {
      const node = elementRef?.current
  
      if (node) {
        node.addEventListener('mouseenter', handleMouseEnter)
        node.addEventListener('mouseleave', handleMouseLeave)
  
        return () => {
          node.removeEventListener('mouseenter', handleMouseEnter)
          node.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
    }, [elementRef])
  
    return value
 }
