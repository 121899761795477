import React, {useEffect,useState} from 'react';

import useWindowDimensions from '../../helpers/useWindowDimensions';

export const AdminMessages = function AdminMessages(props) {
  const { height, width } = useWindowDimensions();
  const { messages, loadMessages } = props;

  return (
  <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
    <div style={{width:Math.min(width*.9,500), display:'flex', alignItems:'center', flexDirection:'column', backgroundColor:'#16bac5ff', borderRadius:20}}>
      <h2 style={{color:'white'}}>Messages</h2>
      {messages.map((obj, index)=> {
        var d = new Date(obj.time);
        return (
          <div style={{marginBottom:20, width:width*.9, display:'flex', alignItems:'center', flexDirection:'column'}}>
            <h5 style={{color:'white', margin:0}}>name: {obj.name}</h5>
            <h5 style={{color:'white', margin:0}}>email: {obj.email}</h5>
            <h5 style={{color:'white', margin:0}}>phone: {obj.phone}</h5>
            <h5 style={{color:'white', margin:0}}>time: {d.toLocaleTimeString()} {d.toLocaleDateString()}</h5>
            <h4 style={{color:'white', marginTop:10}}>message: {obj.message}</h4>
          </div>
        )
      })}
      <button onClick={()=> loadMessages()}>load messages</button>
    </div>

  </div>
  );
};
