import React, {useEffect,useState} from 'react';
import Typist from 'react-typist';
import Wave from 'react-wavify'

import useWindowDimensions from '../../helpers/useWindowDimensions';
import styles from './styles.css';
import custom from '../../assets/images/icons/custom.png';
import designer from '../../assets/images/icons/designer.png';
import calendar from '../../assets/images/icons/calendar.png';
import notification from '../../assets/images/icons/notification.png';
import video from '../../assets/images/icons/video.png';
import web from '../../assets/images/icons/web.png';
import front from '../../assets/images/icons/front.png';
import learn from '../../assets/images/icons/learn.png';


export const BuildItems = function BuildItems(props) {
  const { height, width } = useWindowDimensions();

  return (
    <div style={{width:'100%', backgroundColor:'white', position:'relative', display:'flex', alignItems:'center', flexDirection:'column', paddingBottom:40}}>
      <div style={{width:'100%', height:150, backgroundColor:'#16bac5', position:'absolute', top:0, zIndex:0}}></div>
      <div className="Row-Build">
        <div className="Item-Build1">
          <img src={designer} className="image-Build"/>
          <div className="Item-Text">Front-End</div>
          <div style={{marginTop:13}}></div>
          <ul className="list-item">
            <li className="list-styled">Custom and pre-built components</li>
            <div style={{marginTop:10}}></div>
            <li className="list-styled">HTML, React, CSS, Bootstrap</li>
          </ul>
        </div>
        <div className="Item-Build2">
          <img src={front} className="image-Build"/>
          <div className="Item-Text">Designer</div>
          <div style={{marginTop:13}}></div>
          <ul className="list-item">
            <li className="list-styled">Design simple and interactive UIs</li>
            <div style={{marginTop:10}}></div>
            <li className="list-styled">Web, Mobile, Logos, Dribbble, Figma</li>
          </ul>
        </div>
        <div className="Item-Build2">
          <img src={custom} className="image-Build"/>
          <div className="Item-Text">Backend</div>
          <div style={{marginTop:13}}></div>
          <ul className="list-item">
            <div style={{marginTop:10}}></div>
            <li className="list-styled">Create secure and structured backends</li>
            <div style={{marginTop:10}}></div>
            <li className="list-styled">NodeJS/Express and Django/Python</li>
          </ul>
        </div>
        <div className="Item-Build3">
          <img src={learn} className="image-Build"/>
          <div className="Item-Text">Learn</div>
          <div style={{marginTop:13}}></div>
          <ul className="list-item">
            <li className="list-styled">Always learning/trying out new stuff</li>
            <div style={{marginTop:10}}></div>
            <li className="list-styled">Medium, StackOverFlow, CodeAcademy, freecodecamp</li>
          </ul>
        </div>
      </div>

    </div>
  );
};
