import firebase from 'firebase/app'
import "firebase/firestore";
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyByvy5qmlepJbuM6ChiA8pKrocTgg6tdOM",
  authDomain: "barcodescanner5-ec972.firebaseapp.com",
  databaseURL: "https://barcodescanner5-ec972.firebaseio.com",
  projectId: "barcodescanner5-ec972",
  storageBucket: "barcodescanner5-ec972.appspot.com",
  messagingSenderId: "519117173787",
  appId: "1:519117173787:web:df14d0ad1c3a47ee0a34be"
};


export const firebaseApp = firebase.initializeApp(firebaseConfig);
