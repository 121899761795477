
export const VisibleBlock = (props) => {
    const { item } = props;

    if (item === 0) return <div className="hover-point-visble"></div>
    return (
        <div className="hover-point-visble">{item===1 ? 'X':'O'}</div>
    )
};


export default VisibleBlock;



