import React, { useState } from 'react';

import {gameResult} from './functions/determineWinner.js';
import {minimax} from './functions/minimax.js';
import {optionsList} from './functions/optionsList.js';
import {logBoard} from './functions/logBoard.js';

import ClickBlock from './components/ClickBlock';
import VisibleBlock from './components/VisibleBlock';
import Board from './components/Board';
import Result from './components/Result';
import './styles.css'

export default function TicTac() {

  const [boardArray, setBoardArray] = useState([0,0,0,0,0,0,0,0,0,]);
  const [result, setResult] = useState(0);

  function updateBoard(tab) {

    if (boardArray[tab[0]] !== 0 || result) {
        return;
    }

    let newBoard;
    if (tab[1]===1) {
      newBoard = boardArray.slice();
      newBoard[tab[0]] = 1;

      logBoard(newBoard)
      setBoardArray(newBoard)
    }

    //Check if game has ended
    let gameStateResult = gameResult(newBoard)
    if (gameStateResult !== 0) {
      setResult(gameStateResult);
      return;
    };

    //Check if game is a stalemate
    if (optionsList(newBoard) === undefined || optionsList(newBoard).length === 0) {
      setResult(10);
      return;
    };


    if (tab[1]===1) {
      let minimaxArray = minimax([newBoard,false])
      let options = optionsList(newBoard);
      let computerIndex;
      if (Array.isArray(minimaxArray)) {
        computerIndex = minimaxArray.indexOf(Math.min(...minimaxArray));
      }
      else {
        computerIndex = minimaxArray;
      }
      let computerMove = options[computerIndex];
      newBoard[computerMove] = 2;
      setBoardArray(newBoard);

      let gameStateResult2 = gameResult(newBoard);
      if (gameStateResult2 !== 0) {
        setResult(gameStateResult2);
        return;
      }
    }


  }

  function resetGame() {
    setBoardArray([0,0,0,0,0,0,0,0,0,]);
    setResult(0);
  }

  return (
    <div className="TicTac">
      <h1 className="TicTac-Title">I didn't know what else to add so here's an unbeatable Tic Tac Toe game using the <a style={{color:'#16bac5'}} href="https://en.wikipedia.org/wiki/Minimax">Minimax Function</a></h1>

      {result === 0 ? (<h2 style={{fontWeight:'bold'}}>Your turn</h2>):
      (<h2 style={{fontWeight:'bold'}}>{result !== 10 ? "You Lost":"Stalemate"}</h2>)}

      <div className="box" style={{paddingTop:'20px'}}>
        {result === 0 ? (
        <>
          {boardArray.map((item, idx) => (
            <ClickBlock key={'cl'+idx} result={result} click={()=> updateBoard([idx,1])} item={item}/>
          ))}
        </>
        ):(null)}
        <div className="box-contents">
          {boardArray.map((item, idx) => (
            <VisibleBlock key={'vi'+idx} item={item}/>
         ))}
         <Board />
         <Result reset={()=> resetGame()} result={result} />
        </div>
      </div>
    </div>
  );
}


// {boardString.map((item, idx) => (
//   <div className="block" onClick={()=> updateBoard([idx,1])}>{item}</div>
// ))}