import React, {useEffect,useState} from 'react';
import Typist from 'react-typist';
import Wave from 'react-wavify'

import useWindowDimensions from '../../helpers/useWindowDimensions';
import styles from './styles.css'
import face from '../../assets/images/cartoon.png'
import { addNote } from '../../helpers/api'
import { Footer } from '../../components/Footer/Footer'

export const ContactMe = function ContactMe(props) {
  const { height, width } = useWindowDimensions();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [warning, setWarning] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function submit() {
    if (name && email && message) {
      addNote(email, name, message, phone).then((data) => {
        console.log(data)
        setSubmitted(true)
        setWarning(false)
      }, (err) => {
        console.log(err);
      });
    }
    else {
      setWarning(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column'}}>
      <div style={{width:'100%', position:'relative', display:'flex', alignItems:'center', flexDirection:'column', marginTop:15}}>
        <div style={{width:'100%', height:1, backgroundColor:'#cccccc', position:'absolute', top:70}}></div>
        <img src={face} className="Face2"/>
      </div>

      {!submitted ? (
      <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column', marginBottom:60}}>
        <div className="Contact-text">How can I help you today? Thanks for taking the time to reach out</div>

        <form>

          <div className="Contact-Top-Row">
            <label className="Contact-label">
              Name:
              <input type="text" name="name" className="Contact-input" value={name} onChange={(e)=> setName(e.target.value)}/>
            </label>
            <div style={{width:10}}></div>
            <label className="Contact-label">
              Email:
              <input type="text" name="name" className="Contact-input" value={email} onChange={(e)=> setEmail(e.target.value)}/>
            </label>
          </div>
          <label className="Contact-label">
            Phone: (Optional)
          <input type="text" name="name" className="Contact-input" value={phone} onChange={(e)=> setPhone(e.target.value)}/>
          </label>
          <label className="Contact-label2">
            Message:
            <textarea type="text" name="name" className="Contact-input2" value={message} onChange={(e)=> setMessage(e.target.value)}/>
          </label>
        </form>
        {warning? (<div className="Contact-Warning-Text">Missing info</div>):(null)}
        <div className="Contact-Submit" onClick={()=> submit()}>Submit</div>
      </div> ):(
        <div style={{marginBottom:60}}>
          <div className="Contact-text">Thanks for reaching out!</div>
          <div className="Contact-text">I usually get back within the day</div>
        </div>
      )}

      <Footer box={false}/>
    </div>
  );
};
