import line1 from '../../../assets/images/tictac/test3.png';
import line2 from '../../../assets/images/tictac/test4.png';

export const Board = () => {

    return (
        <div className="Board">
            <div className="Board-Horizontal">    
                <img className="Board-Line" src={line1} alt="-"/>
                <img className="Board-Line" src={line2} alt="-"/>
            </div> 
            <div className="Board-Vertical">    
                <img className="Board-Line" src={line1} alt="-"/>
                <img className="Board-Line" src={line2} alt="-"/>
            </div>
        </div>
    );
};


export default Board