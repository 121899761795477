import React, { useEffect, useState } from "react";
import Typist from "react-typist";
import Wave from "react-wavify";

import "./styles.css";
import face from "../../assets/images/cartoon.png";
import hardware from "../../assets/images/hardware.png";
import { BuildItems } from "../../components/BuildItems/BuildItems";
import { RecentWork } from "../../components/RecentWork/RecentWork";
import { Footer } from "../../components/Footer/Footer";
import TicTac from "../../components/TicTac/TicTac";

export const Home = function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={face} className="Face" alt="missing" />
      <Typist className="Main-text">
        Hi, I'm Mike. I'm a Full-Stack Developer.
      </Typist>

      <div style={{ marginTop: 100 }} />

      <div style={{ position: "relative", width: "100%" }}>
        <Wave
          fill="#16bac5"
          paused={false}
          options={{
            height: 12,
            amplitude: 20,
            speed: 0.25,
            points: 3,
          }}
        />

        <div
          style={{
            width: "100%",
            backgroundColor: "#16bac5",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            transform: `translate(${0}px, ${-10}px)`,
          }}
        >
          <img src={hardware} className="hardware" alt="missing" />
          <div className="greet">Hi, I’m Mike. Nice to meet you.</div>
          <div className="greet2" style={{ marginBottom: 30 }}>
            I love developing awesome ideas and working in teams. I’m currently
            working for VMLY&R and I have 4+ years of experience developing
            Web/Mobile apps and Backends. If your team is looking for someone
            who is hard working and is always learning, feel free to reach out.
          </div>
          <BuildItems />
        </div>

        <RecentWork />
        <TicTac />
        <Footer box={true} />
      </div>
    </div>
  );
};
