import React, { useEffect, useState } from "react";
import Typist from "react-typist";
import Wave from "react-wavify";

import useWindowDimensions from "../../helpers/useWindowDimensions";
import styles from "./styles.css";
import { Footer } from "../../components/Footer/Footer";
import profile from "../../assets/images/profile2.png";

export const AboutMe = function AboutMe(props) {
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="About-Pic-View">
        <img src={profile} className="About-Pic" />
      </div>
      <div className="About-Title">About Me</div>
      <div className="About-Description">
        Hi, I'm Mike Murray. I'm a Full-Stack Software Developer and I currently
        work at VMLY&R
      </div>

      <div className="About-Description">
        I've built numerous Web/Mobile Apps, 10 mobile apps (8 published, 2
        MVPs), and backends. My code/framework stack includes Javascript,
        Typescript, Python, React, Redux, HTML, CSS, React Native, NodeJS.{" "}
      </div>
      <div className="About-Description">
        I grew around Metro Detroit and went to The University of Michigan,
        graduating with a Mechanical Engineering degree. I spend my free time
        playing hockey, hanging with friends, and trying not to watch too much
        Youtube.
      </div>

      <div className="About-Title">Work</div>
      <div className="About-Description">
        My recent work includes Web development for{" "}
        <a href="https://www.vmlyr.com/" className="About-Link">
          VMLY&R
        </a>
        ,{" "}
        <a href="https://app.joygage.com/signup" className="About-Link">
          Joygage
        </a>
        ,{" "}
        <a href="http://www.syphustraining.com/" className="About-Link">
          Syphus Training
        </a>
        ,{" "}
        <a href="https://www.wegoalapp.com/" className="About-Link">
          WeGoal
        </a>
        , and <a>StemWalkers</a>. The App for stemwalkers was{" "}
        <a
          href="https://source.asce.org/new-app-incentivizes-people-to-do-the-right-thing/?unapproved=5432&moderation-hash=fcb65ece98b3765498b67266384c8b6b#comment-5432"
          className="About-Link"
        >
          featured
        </a>{" "}
        on the American Society of Civil Engineers website.
      </div>

      <div style={{ marginTop: 100 }}></div>
      <Footer box={true} />
    </div>
  );
};
