import React, {useEffect,useState} from 'react';

import useWindowDimensions from '../../helpers/useWindowDimensions';

export const AdminLogin = function AdminLogin(props) {
  const { height, width } = useWindowDimensions();

  const [admin, setAdmin] = useState(false);
  const [user, setUser] = useState(false);
  const [password, setPassword] = useState(false);
  const [warning, setWarning] = useState(null);

  function login() {
    if (user==process.env.REACT_APP_USER && password==process.env.REACT_APP_USER_KEY) {
      props.login()
    }
    else {
      setWarning('Wrong Sign-in')
    }
  }

  return (
    <div style={{width:'100%', display:'flex', alignItems:'center', flexDirection:'column'}}>
      {warning ? (<h3>{warning}</h3>):(null)}
      <div className="Admin-Form">
        <label >User:</label>
        <input className="Admin-Input" type="text" onChange={(e)=> setUser(e.target.value)}/>
        <label >Password:</label>
        <input className="Admin-Input" type="text" onChange={(e)=> setPassword(e.target.value)}/>
        <input type="submit" value="Submit" onClick={()=> login()}/>
      </div>
    </div>
  );
};
