import React, {useEffect,useState} from 'react';

import useWindowDimensions from '../../helpers/useWindowDimensions';

export const AdminTracker = function AdminTracker(props) {
  const { height, width } = useWindowDimensions();
  const { trackers, loadMessages } = props;

  return (
  <div style={{display:'flex', alignItems:'center', flexDirection:'column', marginBottom:100}}>
    <div style={{width:Math.min(width*.9,500), display:'flex', alignItems:'center', flexDirection:'column', backgroundColor:'#16bac5ff', borderRadius:20}}>
      <h2 style={{color:'white'}}>Tracker</h2>
      {trackers.map((obj, index)=> {
        var d = new Date(obj.time);
        return (
          <div style={{marginBottom:20, width:width*.9, display:'flex', alignItems:'center', flexDirection:'column'}}>
            <h5 style={{color:'white', margin:0}}>id: {obj.id}</h5>
            <h5 style={{color:'white', margin:0}}>time: {d.toLocaleTimeString()} {d.toLocaleDateString()}</h5>
            <h5 style={{color:'white', margin:0}}>count: {obj.count}</h5>
          </div>
        )
      })}
      <button onClick={()=> loadMessages()}>load trackers</button>
    </div>

  </div>
  );
};
