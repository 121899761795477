import React, { useEffect, useState } from "react";

import useWindowDimensions from "../../helpers/useWindowDimensions";
import styles from "./styles.css";
import ebin from "../../assets/images/recent/recent-ebin.png";
import fridge from "../../assets/images/recent/recent-fridge.png";
import finance from "../../assets/images/recent/recent-finance.png";
import click from "../../assets/images/recent/recent-click.png";
import welcome from "../../assets/images/recent/login_signup.png";
import logo from "../../assets/images/recent/ls_logo3x.png";
import syphus from "../../assets/images/recent/syphus.jpg";
import syphusLogo from "../../assets/images/recent/sLogo.svg";
import apple from "../../assets/images/apple_store.png";
import google from "../../assets/images/googleplay.png";
import vml from "../../assets/VMLY&R_Logo3.png";

export const RecentWork = function RecentWork(props) {
  const { height, width } = useWindowDimensions();
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="recent-text">Recent Work</div>
      <div className="recent-text2">
        Here are some website and mobile projects I released recently.
        Interested in more information?
        <a
          href={"mailto:mfmurray@umich.edu"}
          style={{ fontWeight: "bold", color: "#16bac5", cursor: "pointer" }}
        >
          {" "}
          Email me
        </a>{" "}
      </div>
      <div className="recent-main-row">
        <div className="vml-square">
          <div
            style={{
              height: 300,
              width: 300,
              overflow: "hidden",
              borderRadius: 20,
            }}
          >
            <img src={vml} className="web-pic" />
          </div>
          <div className="web-title-vml">VMLY&R</div>

          <div className="vml-square2">
            <div className="recent-app-title">VMLY&R</div>
            <div className="recent-app-description">
              Building Ford's New Eccomerce Site
            </div>
            <div className="recent-stores">
              <a style={{ fontSize: 20, color: "white", fontFamily: "sans" }}>
                {" "}
                <a>Visit </a>{" "}
                <a style={{ color: "white" }} href={"https://www.vmlyr.com/"}>
                  Website
                </a>{" "}
              </a>{" "}
            </div>
          </div>
        </div>

        <div className="web-square">
          <div
            style={{
              height: 300,
              width: 300,
              overflow: "hidden",
              borderRadius: 20,
            }}
          >
            <img src={welcome} className="web-pic" />
          </div>
          <div className="web-title">
            <img src={logo} className="web-logo" />
          </div>

          <div className="click-square2">
            <div className="recent-app-title">JOYGAGE</div>
            <div className="recent-app-description">
              Website+Mobile App for dimentia help
            </div>
            <div className="recent-stores">
              <a style={{ fontSize: 20, color: "white" }}>
                {" "}
                <a>Visit </a>{" "}
                <a style={{ color: "white" }} href={"https://www.joygage.com/"}>
                  Website
                </a>{" "}
              </a>{" "}
            </div>
          </div>
        </div>

        <div className="finance-square">
          <div className="recent-app-title">The Finance App</div>
          <img src={finance} className="ebin-pic" />

          <div className="finance-square2">
            <div className="recent-app-title" style={{ fontSize: 31 }}>
              The Finance App
            </div>
            <div className="recent-app-description" style={{ fontSize: 25 }}>
              Users track Monthly and Yearly expenses
            </div>
            <div className="recent-stores">
              <img
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/the-finance-app/id1522026969",
                    "_blank"
                  )
                }
                src={apple}
                className="recent-store"
              />
              <img
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.money.MichaelMurray",
                    "_blank"
                  )
                }
                src={google}
                className="recent-store"
              />
            </div>
          </div>
        </div>

        <div className="fridge-square">
          <img src={fridge} className="ebin-pic" />
          <div className="recent-app-title-bot">Fridge Notes</div>

          <div className="fridge-square2">
            <div className="recent-app-title">Fridge Notes</div>
            <div className="recent-app-description">
              Organize schedules, payments, and notes between family
            </div>
            <div className="recent-stores">
              <img
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/fridge-notes/id1532345022",
                    "_blank"
                  )
                }
                src={apple}
                className="recent-store"
              />
              <img
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.FridgeNotes.MichaelMurray",
                    "_blank"
                  )
                }
                src={google}
                className="recent-store"
              />
            </div>
          </div>
        </div>

        <div className="web-square">
          <div
            style={{
              height: 300,
              width: 300,
              overflow: "hidden",
              borderRadius: 20,
            }}
          >
            <img src={syphus} className="web-pic2" />
          </div>
          <div className="web-title2">
            <img src={syphusLogo} className="web-logo" />
          </div>

          <div className="syphus-square2">
            <div className="recent-app-title" style={{ color: "black" }}>
              Syphus Training
            </div>
            <div className="recent-app-description" style={{ color: "black" }}>
              Website for High-Intensity training Gym
            </div>
            <div className="recent-stores">
              <a style={{ fontSize: 20, color: "black" }}>
                {" "}
                <a>Visit </a>{" "}
                <a
                  style={{ color: "white" }}
                  href={"http://www.syphustraining.com/"}
                >
                  Website
                </a>{" "}
              </a>{" "}
            </div>
          </div>
        </div>

        <div className="ebin-square">
          <div className="recent-app-title">E-Bin</div>
          <img src={ebin} className="ebin-pic" />

          <div className="ebin-square2">
            <div className="recent-app-title">E-Bin</div>
            <div className="recent-app-description">
              Mobile app that rewards users for recycling.
            </div>
            <div className="recent-stores">
              <img
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/e-bin/id1482865174",
                    "_blank"
                  )
                }
                src={apple}
                className="recent-store"
              />
              <img
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.BottlesforBucks.BottleforBucks",
                    "_blank"
                  )
                }
                src={google}
                className="recent-store"
              />
            </div>
          </div>
        </div>

        <div className="click-square">
          <img src={click} className="ebin-pic" />
          <div className="recent-app-title-bot">Click of War</div>

          <div className="click-square2">
            <div className="recent-app-title">Click of War</div>
            <div className="recent-app-description">
              Social experiment designed clicking game
            </div>
            <div className="recent-stores">
              <img
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/click-of-war/id1507584094",
                    "_blank"
                  )
                }
                src={apple}
                className="recent-store"
              />
              <img
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.go.MichaelMurray",
                    "_blank"
                  )
                }
                src={google}
                className="recent-store"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
