
import { firebaseApp } from "../firebase";
import firebase from 'firebase/app'

const db = firebaseApp.firestore()


export function counter(id) {
  countEmail(id).then((data)=> {
    if (data=="new") {
      addId(id).then((data2)=> {
        console.log(data2)
      }, (err) => {
        console.log(err);
      });
    }
    else {
      addCount(data).then((data2)=> {
        console.log(data2)
      }, (err) => {
        console.log(err);
      });
    }
  }, (err) => {
    console.log(err);
  });
}
//



export async function addNote(email, name, message, phone) {
  const ref = db.collection("messages")
  var d = new Date();
  var n = d.getTime();

  return new Promise((resolve, reject) => {
    ref.add({
      email: email,
      name: name,
      message: message,
      phone: phone,
      time: n
    })
    .then(function(docRef) {
      resolve(docRef.id);
    })
    .catch(function(error) {
      console.error("Error adding document: ", error);
      reject(error);
    });
  });
}


export async function countEmail(id) {
  const ref = db.collection("tracker")
  var array = []
  return new Promise((resolve, reject) => {
    ref.where("id", "==", id).get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            array.push(doc.id)
        });
        if (array.length>0) {
          resolve(array[0])
        }
        else {
          resolve('new')
        }
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
        reject(error)
    });
  });
}



export async function addId(id) {
  var d = new Date();
  var n = d.getTime();
  const ref = db.collection("tracker")

  return new Promise((resolve, reject) => {
    ref.add({
      id: id,
      count: 1,
      time: n
    })
    .then(function(docRef) {
      resolve(docRef.id);
    })
    .catch(function(error) {
      console.error("Error adding document: ", error);
      reject(error);
    });
  });
}


export async function addCount(id) {
  var d = new Date();
  var n = d.getTime();
  const ref = db.collection("tracker").doc(id)

  return new Promise((resolve, reject) => {
    ref.update({
      time: n,
      count: firebase.firestore.FieldValue.increment(1)
    })
    .then(function() {
        resolve('+1');
    })
    .catch(function(error) {
        console.log("Error getting document:", error);
        reject(error);
    })
  });
}





export async function getMessages() {
  const ref = db.collection("messages")
  var array = []
  return new Promise((resolve, reject) => {
    ref.get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            array.push(doc.data())
        });
        resolve(array);
    })
    .catch((error) => {
      reject(error);
        console.log("Error getting documents: ", error);
    });
  });
}

export async function getTracker() {
  const ref = db.collection("tracker")
  var array = []
  return new Promise((resolve, reject) => {
    ref.get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            array.push(doc.data())
        });
        resolve(array);
    })
    .catch((error) => {
      reject(error);
        console.log("Error getting documents: ", error);
    });
  });
}
